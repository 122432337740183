import React from 'react'
import './Styles.css';
import { Link } from 'react-router-dom';

function ErrorPage() {



    return (
        <div id="Home">
            <div id="footerhome">
            <div id="quebralinha404">
                <p>Algo saiu do esperado...</p>
            </div>
            <Link to="/" className="buttoncategorycommunity">
                    Voltar ao Lab X
                </Link>

            </div>
        </div>

    )
}
export default ErrorPage;