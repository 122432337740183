import React, { useContext } from 'react'
import './Styles.css';
import HomeLayout from '../Layout/Home'
import StoreContext from '../../Storage/Context';
import { Redirect } from 'react-router-dom';
import { ThreeHorseLoading } from 'react-loadingg';
import ProductViewList from '../../components/ProductView';
function ViewPage() {

    const { productToken } = useContext(StoreContext);


    if(!productToken){
        return (<Redirect to="/" />)
    }

    if (productToken == "carregando") {
        return <ThreeHorseLoading width="100%" />;
    } else {

    return (
        <div id="Home">
            <HomeLayout homego="true" />
            <div id="footerviewpage">
            <ProductViewList idproduct={productToken}/>
            </div>
        </div>
        

    )
    }
}

export default ViewPage;