import { createContext } from 'react';

const StoreContext = createContext({
  token: null,
  setToken: () => {},
  productToken: null,
  setProductView: () => {},
  CommentID: null,
  setCommentID: (CommentID: string) => {},
});

export default StoreContext;