import React, { useEffect, useContext } from 'react'
import PeopleTest from '../../Sources/Image/peopletest.png'
import iconLike from '../../Sources/Image/like-icon-23x.png'
import iconView from '../../Sources/Image/view_sd2x.png'
import api from '../../api/api';
import StoreContext from '../../Storage/Context';
import ReactScrollbar from 'react-scrollbar';
const CommunityPeopleView = ({ id, nameproduct, description, price, shopping, view, like, link, ...rest }) => {

    const { token } = useContext(StoreContext);

    async function LikeC() {
        api.post('likecomment', {
            idCommet: id,
            userid: token,
        })
    }

    const myScrollbar = {
        width: "100%",
        height: "80%",
      };


    return (
        <>
            <div className="like_rightcommunity">
                <img src={iconLike} alt="Likes" /> {like}
            </div>

            <div id="right2community_view">
                Comunidade: Comentários
                <div className="openmyproductlistcomment_c">
                <div className="input-block-adx">
                    
                <ReactScrollbar style={myScrollbar}>
                <div id="openmyproductlistcomment">

                <div className="openmyproductlistcomment_v">
    <div className="openmyproductlistcomment_a">
       TEste
    </div>
        <div className="openmyproductlistcomment_e">
        </div>

    </div>
    </div>

</ReactScrollbar>


    <input placeholder="Comentar no POST &#128512;"
    />
    </div> </div>


                </div>


            <div className="view_rightcommunity">
                <img src={iconView} alt="View" /> {view}
            </div>
            <div id="rightcommunity_view">
                <div className="view_likecommunity">
                    <img onClick={LikeC} src={iconLike} alt="Likes" /> Curtir
            </div>

                <div className="people_rightcommunity">
                    <img src={PeopleTest} alt="" />{nameproduct}</div>
                <div className="you_rightcommunity">
                    Descrição <br />
                    {description}
                </div>


                <div className="price_rightcommunity">
                    R$ {price} | {shopping}
                </div>



                <button onClick={() => window.open(link, "_blank")} className="buttonviewcommunitylink">
                    Consultar Promoção
        </button>

            </div>


        </>
    );
}


export default CommunityPeopleView;