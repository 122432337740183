import React, { InputHTMLAttributes, useState, FormEvent } from 'react'
import './Styles.css';
import VectorCellGamer from '../../Sources/Image/game-4448065_960_720.jpg'
import logo from '../../Sources/Image/logox.png';
import { Link, useHistory } from 'react-router-dom';
import api from '../../api/api';



interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;

}
function RegisterIndex(){

const history = useHistory();
const [NameDefinied, setNameDefinied] = useState('');
const [EmailDefinied, setEmailDefinied] = useState('');
const [PasswordDefinied, setPasswordDefinied] = useState('');
const [PhoneDefinied, setPhoneDefinied] = useState('');

let Mensagem;
let EmailMensagem;
let PassMensagem;
let PhoneMensagem;


if (NameDefinied) {
    var caracteresDigitados =  NameDefinied.length;
    if(caracteresDigitados < 6){
        Mensagem = ("Nome muito curto");
    }
}


if (EmailDefinied) {
    caracteresDigitados =  EmailDefinied.length;
    if(caracteresDigitados < 6){
        EmailMensagem = ("E-mail inválido");
    }
}

if (PasswordDefinied) {
    caracteresDigitados =  PasswordDefinied.length;
    if(caracteresDigitados < 6){
        PassMensagem = ("Senha curta");
    }
}

if (PhoneDefinied) {
    caracteresDigitados =  PhoneDefinied.length;
    if(caracteresDigitados < 11){
        PhoneMensagem = ("Telefone inválido");
    }
}

if (Mensagem){

}
    async function handleCreateClass(e: FormEvent){

    e.preventDefault();

     await api.post('createuser',{
        name: NameDefinied,
        password: PasswordDefinied,
        email: EmailDefinied,
        phone: PhoneDefinied
    }).then(() => {
        history.push('/')
    }).then((response) => {
        console.log(response)
    }).catch((e) => {
        return e;
    });
}


    return(
        <div id="Auth">
            <div id="medium-bar">
                <img src={VectorCellGamer} alt=""/>
            </div>            <form onSubmit={handleCreateClass}>
            <div id="barsideregister">
               <div id="containerAcesso">
            <div id="TextProject">
            <Link to="/">
            <img src={logo} alt=""/> 
                </Link>
            </div>
                  <div className="input-block">
                  <input placeholder="Nome" 
                    value={NameDefinied}
                    onChange={(e) => {setNameDefinied(e.target.value)}}
                  />
                  <div className="mensagemalert">
                  <p>{Mensagem}</p>
                  </div>
                  {NameDefinied &&
                  <input placeholder="E-mail" type="email"
                  value={EmailDefinied}
                  onChange={(e) => {setEmailDefinied(e.target.value)}}
                  />}
                  <div className="mensagemalert">
                  <p>{EmailMensagem}</p>
                  </div>
                  {NameDefinied && EmailDefinied &&
                  <input placeholder="Telefone" type="number"
                  value={PhoneDefinied}
                  onChange={(e) => {setPhoneDefinied(e.target.value)}}
                  />}
                  <div className="mensagemalert">
                  <p>{PhoneMensagem}</p>
                  </div>{NameDefinied && EmailDefinied && PhoneDefinied &&
                  <input placeholder="Senha" type="password"
                  value={PasswordDefinied}
                onChange={(e) => {setPasswordDefinied(e.target.value)}}
                  />}
                  <div className="mensagemalert">
                  <p>{PassMensagem}</p>
                  </div>
                  {NameDefinied && EmailDefinied && PhoneDefinied && PasswordDefinied &&
                  <button className="okregister" type="submit">
                    Participar
                    </button>}
                                      </div></div>
                                      
            </div></form>
        </div>

    )
    
}


export default RegisterIndex;