import React from 'react'
import './StylesMySetup.css';
import HomeLayout from '../Layout/Home'
import PeopleTest from '../../Sources/Image/peopletest.png'
import { useState } from 'react';
import ImageUploader from "react-images-upload";




const MySetupPage = ({ ...rest }) => {


    const [TypeMenu, setTypeMenu] = useState('devices');
    const [Filetyper, setFIle] = useState('devices');

    async function menugopassword() {
        setTypeMenu("password")
    }
    async function menugodevices() {
        setTypeMenu("devices")
    }
    async function menugogeneral() {
        setTypeMenu("general")
    }

    async function buttonok() {
        setTypeMenu("general")
    }


    return (
        <div id="Home">
            <HomeLayout
                homego="true"
                pagead="true"
            />
            <div id="MySetup_background">


                <div className="Componets_MySetup">
                   <p> Processador</p>

                </div>

                <div className="Componets_MySetup">
                <p> Placa de Vídeo</p>

                </div>

                <div className="Componets_MySetup">
                <p> Fonte</p>

                </div>

                <div className="Componets_MySetup">
                <p> Memória Ram</p>

                </div>


                <div className="Componets_MySetup">
                <p> Gabinete</p>

                </div>


            </div>  </div>




    )
}

export default MySetupPage;