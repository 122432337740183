import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import api from '../../api/api';
import ReactScrollbar from 'react-scrollbar';
import { IoIosCloseCircleOutline, IoMdChatboxes } from "react-icons/io";

interface PageHeaderProps {
    iduser?: string;
}
const MyFriendList: React.FC<PageHeaderProps> = ({ iduser, ...rest }) => {

    const [NameUserLoggin, setNameUserLoggin] = useState("Carregando");
    const [ProfileImageLoggin, setProfileLoggin] = useState("Carregando");
    const [OpenMessageID, setOpenMessage] = useState("Open");

    useEffect(() => {
        api.post('getname', {
            iduser
        }).then(response => {
            const { name, profileimage } = response.data;
            setNameUserLoggin(name);
            setProfileLoggin(profileimage);
        })
    }, []);


    async function OpenMessage() {
        setOpenMessage("X")
    }

    async function CloseMEssage() {
        setOpenMessage("")
    }


    const myScrollbar = {
        width: "100%",
        height: "40%",
    };




    return (
        <>
            <div onClick={OpenMessage} id="chatpeople">
  <img src={ProfileImageLoggin} alt="" /> {NameUserLoggin}
  {  (OpenMessageID === "X") &&
            <div id="chatmessageonPeople">
            <IoMdChatboxes />
            </div>
            }
            </div>
            {  (OpenMessageID === "X") &&
                <>
                
                    <div id="chatpeoplemyfriend">
                        <div onClick={CloseMEssage} id="closechatpeoplemyfriend">
                        <IoIosCloseCircleOutline/>
                        </div>
                        
                    <img src={ProfileImageLoggin} alt="" />   
                    <div id="chatGlobalStyles">
            <ReactScrollbar style={myScrollbar}>
            <div className="openmychatcomment_v">
    <div id="openmychatcomment_a">
    {NameUserLoggin}: Oi olá sadas
    </div>

    </div>

    <div className="openmychatcomment_v">
    <div id="openmychatcomment_a">
    {NameUserLoggin}: Oi olá sadas sadda asd asdasd asda asdda sasa s
    </div>

    </div>

    <div className="openmychatcomment_v">
    <div id="openmychatcomment_a">
    {NameUserLoggin}: Oi olá sadas
    </div>

    </div>


                </ReactScrollbar>

<form>
<div className="chat_comment_c">
                <div className="input-block-adx">
    <input placeholder="Dia um olá &#128512;"
    />  <button type="submit">
        Comentar
        </button>
    </div> </div>

</form>

                    </div>
                    </div>
                </>
            }


        </>

    );
}


export default MyFriendList;