import React, { useContext, FormEvent, useState, useEffect, InputHTMLAttributes } from 'react'
import StoreContext from '../../Storage/Context';
import api from '../../api/api';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../Sources/Image/logoLabX.svg';
import logout from '../../Sources/Image/logout_icon_151219.svg'
import config from '../../Sources/Image/4137161-building-construction-industry-setting_114090.svg'
interface PageHeaderProps extends InputHTMLAttributes<HTMLInputElement> {
    homego?: string;
    pagead?: string;
}
const HomeLayout: React.FC<PageHeaderProps> = ({ homego, pagead, ...rest }) => {
    const { token } = useContext(StoreContext);
    const { setToken } = useContext(StoreContext);
    const history = useHistory();

    const [NameUserLoggin, setNameUserLoggin] = useState("Carregando");
    const [ProfileImageLoggin, setProfileLoggin] = useState("Carregando");


    useEffect(() => {
        api.post('getdata', {
            authkey: token
        }).then(response => {
            const { name, profileimage } = response.data;
            setNameUserLoggin(name);
            setProfileLoggin(profileimage);
        })
    }, []);

    if (!NameUserLoggin) {
        setToken();
    }

    async function LogoutYes(e: FormEvent) {

        e.preventDefault();
        setToken();
        history.push('/')
    }

    async function ConfigYes(e: FormEvent) {
        history.push('/settings')
    }


    return (
        <div id="BarTop">
            <Link to="/" >

            </Link>



            
            <div id="mypeople">
                <img src={ProfileImageLoggin} alt="" />
                <p>{NameUserLoggin}</p>
            </div>
            <div id="BarTopMenuGLobal">
            { (pagead === "true") || <Link to="/mount" className="aduser">
                Montar PC
        </Link>}

            { (homego === "true") && <Link to="/" className="menuhomebutton">
                Home
                </Link>}
                </div>
                <div id="IconsMenuGLobal">
                <div id="ConfigYes">
                <img onClick={ConfigYes} src={config} alt="" />
            </div>
            <div id="logoutYes">
                <img onClick={LogoutYes} src={logout} alt="" />
            </div>
            </div>
        </div>

    );
}


export default HomeLayout;