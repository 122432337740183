import React, { useState, useEffect } from 'react'
import './Styles.css';
import CommunityListView from '../../components/Community/CommunityList.jsx';
import api from '../../api/api';
import MenuCommunityGlobal from '../../components/Community/Menu';


function CommunityPage() {

    const [ViewCommunity, setViewCommunity] = useState([]);




    useEffect(() => {
        api.get('community',{
        }).then(response => {
            setViewCommunity(response.data)
        })


    },[]);



    return (
        <div id="Home">
            <MenuCommunityGlobal />
            <div id="rightcommunity">

             {
                 ViewCommunity.map(( {id,description,price, users_id, shopping, nameproduct} ) =>{
                    return (<CommunityListView key={id} idpublish={id} description={description} price={price} idpeople={users_id} 
                        shopping={shopping} nameproduct={nameproduct} />)})
                }
            </div>

            <div id="menurightcommunity">
                Aqui
                </div>
        </div>

    )
}

export default CommunityPage;