import React, {  useState, useEffect } from 'react'
import api from '../api/api';
import PeopleTest from '../Sources/Image/peopletest.png'


interface PageHeaderProps {
    idproduct?: string;
    comment?: string;
    userid: string;
    definied?: string;
}
const ProductCommentView: React.FC<PageHeaderProps>  = ({idproduct, userid, comment, definied, ...rest}) => {

    const [NameUser, setNameUser] = useState("carregando...");
    const [ProfileImageLoggin, setProfileLoggin] = useState("Carregando");


    useEffect(() => {
        api.post('getname',{
            iduser: userid
        }).then(response => {
            setNameUser(response.data.name)
            setProfileLoggin(response.data.profileimage);
        })


    },[]);


    return(
        <>


    <div className="openmyproductlistcomment_v">
    <img src={ProfileImageLoggin} alt="" /> <a>{NameUser}</a>
    <div className="openmyproductlistcomment_a">
        {comment} 
    </div>
        <div className="openmyproductlistcomment_e">
        {definied}
        </div>

    </div>

</>
    );
}


export default ProductCommentView;