import React, { useState, FormEvent, useContext } from 'react'
import './Styles.css';
import { Link } from 'react-router-dom';
import HomeLayout from '../Layout/Home'
import Rectangle30 from '../../Sources/Image/rectangle3.png'
import kabumlogo from '../../Sources/Image/kabumlogo.jpg'
import pichaulogo from '../../Sources/Image/logo-pichau.png'
import vectorcommunity from '../../Sources/Image/vectoradcommunity.jpg'
import bannerfullad from '../../Sources/Image/carl-raw-modify.png'
import api from '../../api/api';
import StoreContext from '../../Storage/Context';

function ADUser() {

    const [NameDefinied, setNameDefinied] = useState('');
    const [LinkDefinied, setLinkDefinied] = useState('');
    const [ShoppingDefinied, setShoppingDefinied] = useState('Kabum');
    const { token } = useContext(StoreContext);
    const [mensagem, setMensagem] = useState('');
    let link;

    if(ShoppingDefinied === "Kabum"){
        link = "https://www.kabum.com.br/cgi-local/site/produtos/descricao_ofertas.cgi?codigo=000000"
    }

    if(ShoppingDefinied === "Pichau"){
        link = "https://www.pichau.com.br/hardware/placa-de-video"
    }



    async function createAD(e: FormEvent){
        e.preventDefault();
        if(ShoppingDefinied === "Kabum"){
        const ca = await api.post('publishad',{
            linkkabum: LinkDefinied,
            descricao: NameDefinied,
            tokenauth: token,
        }).then((response) => {
           return response.data;
        }).catch((e) => {
            return e;
        });
        const { mensagem } = ca;
        setMensagem(mensagem)
    }

    if(ShoppingDefinied === "Pichau"){
        const ca = await api.post('publishad',{
            linkpichau: LinkDefinied,
            descricao: NameDefinied,
            tokenauth: token,
        }).then((response) => {
           return response.data;
        }).catch((e) => {
            return e;
        });
        const { mensagem } = ca;
        setMensagem(mensagem);
    }




    }



    return (
        <div id="Homead">
            <div id="backgroundad"
            >
                <HomeLayout
                    homego="true"
                    pagead="true"
                />
                <div id="aduserbanner" style={{
                    backgroundImage: "url(" + Rectangle30 + ")"
                }}>
                    <div className="ajudeacomunidade">
                        AJUDE A COMUNIDADE A CRESCER
            </div>
                    <div className="kabumlogo">
                        <img src={kabumlogo} alt="" />
                    </div>
                    <div className="pichaulogo">
                        <img src={pichaulogo} alt="" />
                    </div>
                    <div className="vectorcommunity">
                        <img src={vectorcommunity} alt="" />
                    </div>
                    <div className="barfinishad">
                        Anuncie promoções
                        para a comunidade
            </div>
                </div>
                <div id="dateform-ad">


                { (mensagem === "8XX21100CCM") ||
                <div className="input-block-ad">
<form onSubmit={createAD}>
    <select 
    name="select" id="select"
    value={ShoppingDefinied}
    onChange={(e) => {setShoppingDefinied(e.target.value)}}
    >
        <option value="Kabum">Kabum</option>
        <option value="Pichau">Pichau</option>
    </select>

    <input placeholder={link}
        value={LinkDefinied}
        onChange={(e) => {setLinkDefinied(e.target.value)}}
    />
    <input placeholder="Descreva a promoção..."
        value={NameDefinied}
        onChange={(e) => {setNameDefinied(e.target.value)}}
    />
    <button className="oksubmit-ad" type="submit">
<a>Enviar</a>
</button>
</form>
</div>
}



{ (mensagem === "8XX21100CCM") &&
                    <div id="text-dateformad">
<div className="colordateformad">
    Dados sendo processados
</div>
Obrigado por ajudar a comunidade, nosso sistema irá validar e buscar os valores do produto e em breve será publicado na comunidade da Lab X
<Link to="/mypublish" className="button-dateformad">
                    <a>Ver publicação</a>
                </Link>
                    </div>
}
                </div>
            </div>
        </div>

    )
}

export default ADUser;