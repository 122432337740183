import React, { useState, useEffect, useContext } from 'react'
import './Styles.css';
import api from '../../api/api';
import StoreContext from '../../Storage/Context';
import MenuCommunityGlobal from '../../components/Community/Menu';
import MyPublishCommunityItem from '../../components/Community/MyPublish';
function MyPublishCommmunity() {
    const { token } = useContext(StoreContext);
    const [ViewCommunity, setViewCommunity] = useState([]);

    async function conectar() {
        api.post('viewmypublish', {
            tokenauth: token,
        }).then(response => {
            setViewCommunity(response.data)
        })
    }

    useEffect(() => {
        conectar();
    }, []);



    return (
        <div id="Home"
        >
            <MenuCommunityGlobal />

            <div id="rightcommunity_publish">
            {
                 ViewCommunity.map(( {id,description,price, shopping, watch} ) =>{
                    return (<MyPublishCommunityItem key={id} description={description} watch={watch} price={price} idview={id} 
                        shopping={shopping} />)})
                }
                        </div>
        </div>

    )
}

export default MyPublishCommmunity;