import React, {  useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import PeopleTest from '../../Sources/Image/peopletest.png'
import api from '../../api/api';
import StoreContext from '../../Storage/Context';
import logo from '../../Sources/Image/logox.png';

function MenuCommunityGlobal() {

    const { token } = useContext(StoreContext);
    const { setToken } = useContext(StoreContext);

    const [NameUserLoggin, setNameUserLoggin] = useState("Carregando");

    useEffect(() => {
        api.post('getdata',{
            authkey: token
        }).then(response => {
            const {name} = response.data;
            setNameUserLoggin(name);
        })
    },[]);

    if(!NameUserLoggin){
    setToken();
    }


    return (
        <>
            <div id="communityyou">
            <Link to="/community" >
                <img src={logo} alt=""/> 
            
            </Link>
            <img src={PeopleTest} alt="" />
            
                <p>{NameUserLoggin}</p>


            </div>

            <Link to="/" className="buttoncategorycommunity">
                    Voltar ao Lab X
                </Link>
                <Link to="/mypublish" className="buttoncategorycommunity">
                    Meus Anuncios
                </Link>
                <Link to="/community" className="buttoncategorycommunitylabx">
                    Lab X Home
                </Link>
            </>
    )
}

export default MenuCommunityGlobal;