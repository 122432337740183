import React, { useEffect, useContext, useState } from 'react'
import './Styles.css';
import HomeLayout from '../Layout/Home'
import api from '../../api/api';
import StoreContext from '../../Storage/Context';
import ProductListMap from '../../components/ProductList.jsx'
import { ThreeHorseLoading } from 'react-loadingg';
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom';
import publicIP from 'react-native-public-ip';
import ChatGlobal from '../../components/Chat/ChatGlobal.jsx';

function HomePage() {

    const [products, setProducts] = useState(["carregando"]);
    const { token } = useContext(StoreContext);
    const [StatusFIlter, setStatusFilter] = useState();

    useEffect(() => {
        api.post('random', {
        }).then(response => {
            setProducts(response.data)
        })
    }, []);

    function gpu() {
        setStatusFilter("ok")
        api.post('random', {
            pcfor: "GPU"
        }).then(response => {
            setProducts(response.data)
        })
    }

    function cpu() {
        setStatusFilter("ok")
        api.post('random', {
            pcfor: "CPU"
        }).then(response => {
            setProducts(response.data)
        })
    }

    function ram() {
        setStatusFilter("ok")
        api.post('random', {
            pcfor: "RAM"
        }).then(response => {
            setProducts(response.data)
        })
    }

    function font() {
        setStatusFilter("ok")
        api.post('random', {
            pcfor: "FONTE"
        }).then(response => {
            setProducts(response.data)
        })
    }

    function disk() {
        setStatusFilter("ok")
        api.post('random', {
            pcfor: "DISCO"
        }).then(response => {
            setProducts(response.data)
        })
    }



    function gab() {
        setStatusFilter("ok")
        api.post('random', {
            pcfor: "GABINETE"
        }).then(response => {
            setProducts(response.data)
        })
    }

    function cleanFilter() {
        setStatusFilter("")
        api.post('random', {
        }).then(response => {
            setProducts(response.data)
        })
    }


    publicIP()
        .then(ip => {
        })
        .catch(error => {
        });





    if (products == "carregando") {
        return <ThreeHorseLoading width="100%" />;
    } else {



        return (
            <div id="Home">
                <HomeLayout />

                <Menu height={'30%'} width={'20%'} id="MenuBurger">



                    <Link to="/register" className="barmediumbutton">
                        Inicio
                </Link>
                    <Link to="/aduser" className="barmediumbutton">
                        Anunciar
                </Link>

                    <Link to="/profile" className="barmediumbutton">
                        Perfil
                </Link>

                    <Link to="/community" className="barmediumbutton">
                        Comunidade
                </Link>


                    <Link to="/mysetup" className="barmediumbutton">
                        Meu Setup
                </Link>

                </Menu >


                <div id="menuHomeMedium">

               { (StatusFIlter) &&
                <div onClick={cleanFilter} className="Category_Home Category_FILTER_Home">
                    Limpar Filtro
      </div>
      }


                <div onClick={ram} className="Category_Home Category_RAM_Home">
                    Memória Ram
      </div>

                <div onClick={cpu} className="Category_Home Category_CPU_Home">
                    Processador
      </div>

                <div onClick={gpu} className="Category_Home Category_GPU_Home">
                    Placa de vídeo
      </div>

                <div onClick={disk} className="Category_Home Category_DISK_Home">
                    Discos e SSD
      </div>

                <div onClick={font} className="Category_Home Category_APU_Home">
                    Fontes
      </div>

                <div onClick={gab} className="Category_Home Category_GAB_Home">
                    Gabinetes
      </div>

      </div>
                <div id="footerhome">
                    {
                        products.map(({ id, name, price, image, type }) => {
                            return (<ProductListMap key={id} productid={id} image={image} type={type} name={name} price={price} />)
                        })
                    }


                </div>

                <ChatGlobal />

            </div>

        )
    }
}
export default HomePage;