import React from 'react'
import { useState, useEffect, useContext } from 'react';
import api from '../../api/api';
import StoreContext from '../../Storage/Context';
import ReactScrollbar from 'react-scrollbar';
import MyFriendList from './MyFriendList'


const ChatGlobal = ({ ...rest }) => {


    const [MyFriendChat, setMyFriendChat] = useState(["carregando"]);
    const { token } = useContext(StoreContext);


    useEffect(() => {
        api.post('myfriend', {
            tokenc: token
        }).then(response => {
            setMyFriendChat(response.data)
            console.log("aqui")
        })
    }, []);



    const myScrollbar = {
        width: "100%",
        height: "100%",
    };



    return (
        <div id="chatglobal">
            Amigos & Chat
            <hr></hr>
            <ReactScrollbar style={myScrollbar}>

                {
                    MyFriendChat.map(({ iduser2 }) => {
                        return (<MyFriendList key={iduser2} iduser={iduser2} />)
                    })
                }


            </ReactScrollbar>



        </div>

    );
}


export default ChatGlobal;