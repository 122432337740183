import React, {  useState, useEffect } from 'react'
import { FormEvent } from 'react';
import { useContext } from 'react';
import api from '../api/api';
import StoreContext from '../Storage/Context';
import ProductCommentView from './ProductComment';
import ReactScrollbar from 'react-scrollbar';
import ChatGlobal from './Chat/ChatGlobal';
interface PageHeaderProps {
    idproduct?: string;
}
const ProductViewList: React.FC<PageHeaderProps>  = ({idproduct, ...rest}) => {

    const [NameProduct, setNameProduct] = useState("Carregando");
    const [PriceProduct, setPriceProduct] = useState("...");
    const [CategoryProduct, setCategoryProduct] = useState("Carregando");
    const [imageLink, setImageLink] = useState("...");
    const [linkKabum, setLinkKabum] = useState("vazio");
    const [linkPichau, setLinkPichau] = useState("vazio");
    const [mirror, setMirror] = useState("undifined");
    const [ViewCommunity, setViewCommunity] = useState([]);
    const [CommentPublish, setCommentPublish] = useState('');
    const { token } = useContext(StoreContext);

    useEffect(() => {
       api.post('getpublishpc',{
            publishid: idproduct
        }).then(response => {
            setViewCommunity(response.data.RequeryTokenX)
        })


    },[]);


    const [dt, setDt] = useState(new Date().toLocaleString());
    useEffect(() => {
        let secTimer = setInterval( () => {
            api.post('getpublishpc',{
                publishid: idproduct
            }).then(response => {
                setViewCommunity(response.data.RequeryTokenX)
            })
        },9000)
    
        return () => clearInterval(secTimer);
    }, []);

 


    function reload() {
        api.post('getpublishpc',{
            publishid: idproduct
        }).then(response => {
            setViewCommunity(response.data.RequeryTokenX)
        })



        
    }


    async function createAD(e: FormEvent){
        e.preventDefault();
        if (CommentPublish){
        const ca = await api.post('publishcomment',{
            comment: CommentPublish,
            tokenauth: token,
            productid: idproduct
        }).then((response) => {
           return response.data;
        }).catch((e) => {
            return e;
        });
        await api.post('getpublishpc',{
            publishid: idproduct
        }).then(response => {
            setViewCommunity(response.data.RequeryTokenX)
            console.log(response.data)
        })
    }
        reload();
        setCommentPublish("")
    }





    useEffect(() => {
        api.post('getviewpc',{
            pcfor: idproduct
        }).then(response => {
            const {name, price, image, type, linkkabum, linkpichau, shopping} = response.data[0];
            setNameProduct(name)
            setPriceProduct(price)
            setCategoryProduct(type)
            setImageLink(image)
            setLinkKabum(linkkabum)
            setLinkPichau(linkpichau)
            setMirror(shopping)
        })
    },[]);

    const myScrollbar = {
        width: "100%",
        height: "78%",
        top: '5%',
      };



    return(
        <>
        <div id="openmyproductlist">
        <div id="openmyproductlist-main">
        {NameProduct} 
        </div>
        <div className="Category_ProductView">
        Categoria: {CategoryProduct}
        </div>
        <div className="View_ProductView">
         0 Visualizações
        </div>

        { (mirror === "Kabum") &&
        <div className="Mirror_ProductView KabumMirror">
    Melhor Preço
    </div>
    }
    { (mirror === "Pichau") &&
        <div className="Mirror_ProductView PichauMirror">
    Melhor Preço
    </div>
    }

        <div className="PriceProductView">
    R$ {PriceProduct}
    </div>
        <img src={imageLink} alt="" />
        <div className="Button_Mirror_ProductView">


    { (linkKabum !== "vazio") &&
        <div onClick={()=> window.open(linkKabum, "_blank")} className="KabumMirror_ProductView">
    Kabum
    </div>
    }
    { (linkPichau) &&
    <div onClick={()=> window.open(linkPichau, "_blank")} className="PichauMirror_ProductView">
    Pichau
    </div>
    }
</div>



</div>

<div id="openmyproductlistcomment">
    
    Comentários
    <ReactScrollbar style={myScrollbar}>

    {

                 ViewCommunity.map(( {comment, users_id, definied, likepublish} ) =>{
                    return (<ProductCommentView definied={definied} userid={users_id} comment={comment} />)})
                }

</ReactScrollbar>
<form onSubmit={createAD}>
<div className="openmyproductlistcomment_c">
                <div className="input-block-adx">
                    
    <input placeholder="Comentar &#128512;"
            value={CommentPublish}
            onChange={(e) => {setCommentPublish(e.target.value)}}
    />  <button type="submit">
        Comentar
        </button>
    </div> </div>

</form>

    </div>


    <ChatGlobal />

</>
    );
}


export default ProductViewList;