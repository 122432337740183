import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import AuthIndex from './Pages/Auth/index.jsx'
import RegisterIndex from './Pages/Register'
import HomePage from './Pages/Home/index.jsx';
import MountPC from './Pages/MountMyPC/index.jsx';
import StoreProvider from './Storage/Provider'
import RouterPrivate from './components/Router'
import RoutesPublic from './components/RouterPublic'
import ADUser from './Pages/AdUser';
import CommunityPage from './Pages/Community';
import ViewPage from './Pages/ViewProduct/index.jsx';
import CommunityViewPage from './Pages/Community/view';
import { Route } from 'react-router-dom';
import ErrorPage from './Pages/Home/404';
import MyPublishCommmunity from './Pages/Community/mypublish';
import SettingsAccountPage from './Pages/Home/settings';
import ProfilePage from './Pages/Home/profile';
import MySetupPage from './Pages/Home/mysetup';

function Routes() {
    return (
        <Router>
          <StoreProvider>
            <Switch>
          <RoutesPublic path="/register" component={RegisterIndex} />
          <RoutesPublic path="/login" component={AuthIndex} />
          <RouterPrivate path="/" component={HomePage} exact/>
          <RouterPrivate path="/mount" component={MountPC} />
          <RouterPrivate path="/aduser" component={ADUser} />
          <RouterPrivate path="/community" component={CommunityPage} />
          <RouterPrivate path="/viewproduct" component={ViewPage} />
          <RouterPrivate path="/viewcomment" component={CommunityViewPage} />
          <RouterPrivate path="/mypublish" component={MyPublishCommmunity} />
          <RouterPrivate path="/settings" component={SettingsAccountPage} />
          <RouterPrivate path="/profile" component={ProfilePage} />
          <RouterPrivate path="/mysetup" component={MySetupPage} />
          <Route component={ErrorPage} />
          </Switch>
          </StoreProvider>
        </Router>
    )
}

export default Routes;