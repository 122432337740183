import React from 'react'
import './StylesProfile.css';
import HomeLayout from '../Layout/Home'
import PeopleTest from '../../Sources/Image/peopletest.png'
import { useState } from 'react';
import ImageUploader from "react-images-upload";
import { ChangeEvent } from 'react';
import { useEffect } from 'react';
import api from '../../api/api';
import StoreContext from '../../Storage/Context';
import { useContext } from 'react';




const SettingsAccountPage  = ({ ...rest}) => {


    const [TypeMenu, setTypeMenu] = useState('devices');
    const [Filetyper, setFIle] = useState('devices');
    const [name, setName] = useState('b')
    const { token } = useContext(StoreContext);
    const [images, setImages] = useState<File[]>([])
    const [previewImages, setPreviewImages]= useState<string[]>([])
    const [ProfileImageLoggin, setProfileLoggin] = useState("Carregando");


    useEffect(() => {
        api.post('getdata', {
            authkey: token
        }).then(response => {
            const { name, profileimage } = response.data;
            setProfileLoggin(profileimage);
        })
    }, []);

    function handleSelectImages(event: ChangeEvent<HTMLInputElement>){

        if(!event.target.files){
          return;
        }
    
        const Selectimages = Array.from(event.target.files);
    
        setImages(Selectimages);
    
        const selectedImagesPreview = Selectimages.map(image => {
          return URL.createObjectURL(image);
        });
    
        setPreviewImages(selectedImagesPreview);
      }
    


    async function menugopassword(){
        setTypeMenu("password")
    }
    async function menugodevices(){
        setTypeMenu("devices")
    }
    async function menugogeneral(){
        setTypeMenu("general")
    }

    async function buttonok(){
        setTypeMenu("general")
    }

    console.log(previewImages)

    return (
        <div id="Home">
            <HomeLayout
                homego="true"
                pagead="true"
            />
            <div id="ProfileSettings_background">
            <label htmlFor="images">
                {
                    !previewImages.length ? <img src={ProfileImageLoggin} alt="Foto de Perfil" /> : null
                }
            { previewImages.map(image =>{
                   return(
                     <img src={image} alt={name} key={image} />
                   )
                })}
                <div className="input-block-settings">
                </div>
               </label>

                <label htmlFor="images" className="new-image">
                  
                </label>

                <input multiple onChange={handleSelectImages} type="file" id="images" />



                <div className="ProfileSettings_global">
                                <div className="ProfileConfig_Settings">
                        E-mail
                                <div className="input-block-ad">
                            <input placeholder="roberto@teste.com" />
                        </div>

                    </div>

                    <div className="ProfileConfig_Settings">
                        Telefone
                                <div className="input-block-ad">
                            <input placeholder="12 9123213312" />
                        </div>

                    </div>

                    <div className="ProfileConfig_Settings">
                        Senha
                                <div className="input-block-ad">
                            <input placeholder="**********" />
                        </div>

                    </div>

                    <div className="ProfileConfig_Settings">
                        Senha
                                <div className="input-block-ad">
                            <input placeholder="Senha nova" />
                        </div>

                    </div>

                    <button className="buttonsecuritypass">
                        ATUALIZAR DADOS
                    </button>
                </div>
                { (TypeMenu == "devices") &&
                <div className="ProfileSettings_devices">
                <div className="MyDevices_ProfileSettings">
                    Computador - São Paulo <br></br>
                    IP 127.0.0.1
                                        </div> 
                                        <div className="MyDevices_ProfileSettings">
                    Computador - São Paulo
                                        </div>                </div>}
                
                
                </div></div>

    )
}

export default SettingsAccountPage;