import React from 'react'
import './StylesProfile.css';
import HomeLayout from '../Layout/Home'
import PeopleTest from '../../Sources/Image/peopletest.png'
import { useState } from 'react';
import ImageUploader from "react-images-upload";




const ProfilePage = ({ ...rest }) => {




    
    const [TypeMenu, setTypeMenu] = useState('devices');
    const [Filetyper, setFIle] = useState('devices');

    async function menugopassword() {
        setTypeMenu("password")
    }
    async function menugodevices() {
        setTypeMenu("devices")
    }
    async function menugogeneral() {
        setTypeMenu("general")
    }

    async function buttonok() {
        setTypeMenu("general")
    }


    return (
        <div id="Home">
            <HomeLayout
                homego="true"
                pagead="true"
            />
            <div id="ProfileSettings_background">
                <img src={PeopleTest} alt="" />

                <div className="Profile_MyGeneral">
                    Oque já fiz?

                </div>


                <div className="Profile_MySetup">
                    Meu Setup

                </div>


            </div>  </div>




    )
}

export default ProfilePage;