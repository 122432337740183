import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom';
import StoreContext from '../../Storage/Context';

interface PageHeaderProps {
    description?: string;
    watch?: string;
    idview: string;
    price?: string;
    shopping?: string;
}
const MyPublishCommunityItem: React.FC<PageHeaderProps> = ({ description, watch, idview, price, shopping, ...rest }) => {

    const { setCommentID } = useContext(StoreContext);
    const history = useHistory();
    async function ViewComment(){
        setCommentID(idview)
        return history.push('/viewcomment');
    }

    return (

        <>
               { (watch == "2") && 
                <div className="communitypublishstatus">
                    <p> {description}</p>
                    <div onClick={ViewComment} className="completed_communitypublish">
                        Ver meu comentário
                 </div>
                <div className="pric_communitypublish">
                    R$ {price} | {shopping}
                </div>
                </div>
                }

              { (watch == "1") && 
                <div className="communitypublishstatus">
                    <h4>Em processamento</h4>
                    <p> {description}</p>
                </div>
                }

            { (watch == "3") && 
                <div className="communitypublishstatus">
                    <p> {description}</p>
                </div>
                }

        </>
    )
}

export default MyPublishCommunityItem;