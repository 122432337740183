import React, { useState, useContext } from 'react'
import './Styles.css';
import VectorComputer from '../../Sources/Image/FreeVector-Apple-Computer.jpg'
import logo from '../../Sources/Image/logox.png';
import { Link, useHistory } from 'react-router-dom';
import api from '../../api/api';
import StoreContext from '../../Storage/Context';
import { BoxLoading } from 'react-loadingg';


function AuthIndex(){

const [EmailDefinied, setEmailDefinied] = useState('');
const [PasswordDefinied, setPasswordDefinied] = useState('');
const [MensageDefinied, setMensageDefinied] = useState('BEM VINDO');
const { setToken } = useContext(StoreContext);
const history = useHistory();
if (EmailDefinied) {
    var caracteresDigitados =  EmailDefinied.length;
    if(caracteresDigitados < 6){
    }
}

if (PasswordDefinied) {
    var caracteresDigitadosPass =  PasswordDefinied.length;
    if(caracteresDigitadosPass < 6){
    }
}

    async function login({ user, password })  {
    const response = await api.post('loginauth',{
        email: user,
        password: password,
    }).then((response) => {
        if (!response.data.authkey){
            setMensageDefinied("Erro nas credenciais")
        }
        return response.data.authkey;
    }).catch((e) => {
        console.log(e);
    });
    return { token: response };
}


async function LoginCreateClass(event){
    event.preventDefault();
    const { token } = await login({user: EmailDefinied, password: PasswordDefinied});

    if (token) {
        setToken(token);
        return history.push('/');
      }

}




    return(
        <div id="Auth">
            <div id="barside">
               <form onSubmit={LoginCreateClass}>
               <div id="containerAcesso">
               <div id="TextProject">
            <Link to="/">
            <img src={logo} alt=""/>
                </Link>
            </div>
                <p>{MensageDefinied}</p>
                  <div className="input-block">
                  <input placeholder="E-mail" type="text"
                   value={EmailDefinied}
                   onChange={(e) => {setEmailDefinied(e.target.value)}}
                  />
                  <div className="mensagemalert">
                  </div>{ EmailDefinied &&
                  <input placeholder="Senha" type="password"
                   value={PasswordDefinied}
                   onChange={(e) => {setPasswordDefinied(e.target.value)}}
                  />}
                  <div className="mensagemalert">
                  </div>{ EmailDefinied && PasswordDefinied &&
                  <button className="loginok">
                    ACESSO
                    </button>}

                    { EmailDefinied && PasswordDefinied &&
                  <button className="loginok">
                    ENTRAR
                    </button>}


                    { !EmailDefinied &&
                    <Link to="/register" className="registerok">
                      SUA PRIMEIRA VEZ?
                    </Link>}
                                      </div></div>
                                      </form>
            </div>
        </div>

    )
}


export default AuthIndex;