import React, { useState, useEffect, useContext } from 'react'
import './Styles.css';
import api from '../../api/api';
import StoreContext from '../../Storage/Context';
import CommunityPeopleView from '../../components/Community/CommunityView';
import MenuCommunityGlobal from '../../components/Community/Menu';
function CommunityViewPage() {
    const { CommentID } = useContext(StoreContext);
    const [ViewName, setViewCommunityNameProduct] = useState("Carregando...");
    const [ViewPrice, setViewCommunityPrice] = useState("Carregando...");
    const [ViewShopping, setViewCommunityShopping] = useState("Carregando...");
    const [ListView, setView] = useState("Carregando...");
    const [LikeView, setLike] = useState("Carregando...");
    const [LinkVIew, setLink] = useState("");
    const [Description, setDescription] = useState("Carregando...");
    const [TotalView, setTotalView] = useState("0");
    const { token } = useContext(StoreContext);
    useEffect(() => {
        api.post('viewcomment', {
            idCommet: CommentID,
            userid: token,
        }).then(response => {
            console.log(response);
            setViewCommunityNameProduct(response.data.lanco.nameproduct)
            setViewCommunityPrice(response.data.lanco.price)
            setViewCommunityShopping(response.data.lanco.shopping)
            setView(response.data.lanco.linkcount)
            setLike(response.data.likeincomment)
            setDescription(response.data.lanco.description)
            setTotalView(response.data.totaldeview)
            {
                (response.data.lanco.linkkabum) &&
                setLink(response.data.lanco.linkkabum)
            }
            {
                (response.data.lanco.linkpichau) &&
                setLink(response.data.lanco.linkpichau)
            }
        })

    }, []);



    return (
        <div id="Home"
        >
            <MenuCommunityGlobal />
            <CommunityPeopleView id={CommentID} nameproduct={ViewName}
                like={LikeView} description={Description} price={ViewPrice} shopping={ViewShopping}
                view={TotalView} link={LinkVIew} />
        </div>

    )
}

export default CommunityViewPage;