import React from 'react';
import Context from './Context';
import useStorage from '../utils/useStorage';

const StoreProvider = ({ children }) => {
  const [token, setToken] = useStorage('token');
  const [productToken, setProductView] = useStorage('LabX-ProductView');
  const [CommentID, setCommentID] = useStorage('LabX-Community');
  return (
    <Context.Provider
      value={{
        productToken,
        setProductView,
        token,
        setToken,
        CommentID,
        setCommentID,
      }}
    >
      {children}
    </Context.Provider>
  )
}


export default StoreProvider;