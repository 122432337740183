import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import api from '../api/api';
import CartShop from '../Sources/Image/shoppaymentorderbuy-33_icon-icons.com_73869.svg'
import StoreContext from '../Storage/Context';


interface PageHeaderProps {
    id: number;
    productid: string;
    typeProduct: string;
}
const PageListProps: React.FC<PageHeaderProps> = ({ id, productid, typeProduct, ...rest }) => {

    const [NameProduct, setNameProduct] = useState("Carregando");
    const [PriceProduct, setPriceProduct] = useState("...");
    useEffect(() => {
        api.post('getviewpc',{
            pcfor: productid
        }).then(response => {
            const {name, price} = response.data[0];
            setNameProduct(name)
            setPriceProduct(price)

        })
    },[]);


  return (
    <>
  <div id="mycart_component_item">
          {NameProduct}
          </div>
    </>
  );
}


export default PageListProps;