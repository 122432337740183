import React, { useContext } from 'react'
import StoreContext from '../../Storage/Context';
import { useHistory } from 'react-router-dom';
import PeopleTest from '../../Sources/Image/peopletest.png'
const CommunityListView  = ({ description, nameproduct, price, shopping, idpublish, idpeople, ...rest}) => {
    const { setCommentID } = useContext(StoreContext);
    const history = useHistory();
    async function ViewComment(){
        setCommentID(idpublish)
        return history.push('/viewcomment');
    }


    return(
        <> 
            <div  onClick={ViewComment} id="communitycomment">
             <img src={PeopleTest} alt="" />
        <p>{description.substr(0, 80)} ...</p>

        <div className="desc_communitycomment">
       {nameproduct}
        </div>
        <div className="pric_communitycomment">
        R$ {price} | {shopping}
        </div>
    </div>


    </>
    );
}


export default CommunityListView;