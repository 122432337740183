import React, { useContext } from 'react'
import StoreContext from '../Storage/Context';
import { useHistory } from 'react-router-dom';

const ProductListMap = ({ name, productid, price, image, type, ...rest }) => {
    const { setProductView } = useContext(StoreContext);
    const history = useHistory();
    async function ViewProduct() {
        setProductView(productid)
        return history.push('/viewproduct');
    }



        return (
            <>
            { type == "GPU" &&
                <div onClick={ViewProduct} className="productlist">
                    <img src={image} alt="" />
                    <p>{name} </p>
                    <div className="PriceProductList">
                        {price}
                    </div>
                </div>
}

{ type == "CPU" &&
                <div onClick={ViewProduct} className="productlist productlist_cpu">
                    <img src={image} alt="" />
                    <p>{name} </p>
                    <div className="PriceProductList">
                        {price}
                    </div>
                </div>
}

{ type == "RAM" &&
                <div onClick={ViewProduct} className="productlist productlist_ram">
                    <img src={image} alt="" />
                    <p>{name} </p>
                    <div className="PriceProductList">
                        {price}
                    </div>
                </div>
}

{ type == "DISCO" &&
                <div onClick={ViewProduct} className="productlist productlist_disk">
                    <img src={image} alt="" />
                    <p>{name} </p>
                    <div className="PriceProductList">
                        {price}
                    </div>
                </div>
}




            </>
        );
}


export default ProductListMap;