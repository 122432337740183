import React, { useEffect } from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import api from '../api/api';
import CartShop from '../Sources/Image/shoppaymentorderbuy-33_icon-icons.com_73869.svg'
import StoreContext from '../Storage/Context';
import PageListProps from './ProductMyCart';


interface PageHeaderProps {
  name?: string;
  price?: string;
  type?: string;
  image?: string;
  idproduct: number;
}
const ProductHardwareMap: React.FC<PageHeaderProps> = ({ name, idproduct, price, type, image, ...rest }) => {

  const { token } = useContext(StoreContext);
  const [CardYes, setCardYes] = useState("no");
  const [MessageServer, setMessageServer] = useState("");

  async function createAD() {
    const ca = await api.post('mountmycard', {
      productid: idproduct,
      tokenauth: token,
      producttype: type
    }).then((response) => {
      setMessageServer(response.data.mensagem)
      console.log(response.data)
    })
    setCardYes("yes")

    api.post('mycardlist',{
      tokenauth: token
     }).then(response => {
         setDataHW(response.data)
         console.log(response)
     })
  }

  async function removeObject() {
    const ca = await api.put('mountmycard', {
      productid: idproduct,
      tokenauth: token,
      producttype: type
    }).then((response) => {
      return response.data;
    })
    setCardYes("no")

    api.post('mycardlist',{
      tokenauth: token
     }).then(response => {
         setDataHW(response.data)
         console.log(response)
     })
  }

  const [dataHW, setDataHW] = useState([]);

  useEffect(() => {
     api.post('mycardlist',{
      tokenauth: token
     }).then(response => {
         setDataHW(response.data)
         console.log(response)
     })
 }, [])

  return (
    <>
      <div id="mycart_component">
        Meus Items

        {
                 dataHW.map(({id, productid, typeProduct} ) =>{
                    return <PageListProps key={id} productid={productid} id={id} typeProduct={typeProduct} />
                })
              }

          <div id="buttoneffect-mymc">
          <div onClick={createAD} className="cardfinish-mymc">
            Finalizar
        </div>
        </div>
      </div>

       { (MessageServer == "3902XCVMXNCXC000") &&
      <div id="component-mycart_alert" className="hide">
             Você não pode adicionar esse produto, já existe um da mesma categoria.
      </div>
}

      <div id="component-mymc">
        <img src={image} alt="" />
        <div className="description-mymc">
          {name}
        </div>
        { (CardYes == "no") &&
        <div id="buttoneffect-mymc">
          <div onClick={createAD} className="cardadd-mymc">
            Adicionar
        </div>
        </div>
        }

        { (CardYes == "yes") &&
        <div id="buttoneffect-mymc">
          <div onClick={removeObject} className="cardaddyes-mymc">
            Remover
        </div>
        </div>
        }

        <hr></hr>
        <div className="price-mymc">
          R${price}
        </div>
        <div className="category-mymc">
          {type}
        </div>
      </div>
    </>
  );
}


export default ProductHardwareMap;