import React, { useState } from 'react'
import './Styles.css';
import PCGamer from '../../Sources/Image/gabinetegamer.jpg'
import PCOffice from '../../Sources/Image/pcoffice.jpg'
import HomeLayout from '../Layout/Home';
import ProcessorImg from '../../Sources/Image/chip-1710300.svg'
import MemoryImage from '../../Sources/Image/memory-33549.svg'
import DiskImg from '../../Sources/Image/hdd-154463.svg'
import cabinetImg from '../../Sources/Image/cabinetprf.png'
import fountComputer from '../../Sources/Image/fountcomputer.jpg'
import GPUImg from '../../Sources/Image/gpucx.png'
import ProductHardwareMap from '../../components/ProductHardware'
import api from '../../api/api';
import { IoIosArrowBack } from "react-icons/io";
function MountPC() {

    const [PCDefinied, setPCDefinied] = useState('');
    const [PCDefiniedType, setPCDefiniedType] = useState('');
    const [MyComponent, setMyComponent] = useState('');
    const [dataHW, setDataHW] = useState('Aguardando');
    const [Generic, setGeneric] = useState('Generico');

    async function ComputerHome(event){
        event.preventDefault();
        setPCDefinied("defined")
        setPCDefiniedType("Office")
        SearchGetPC("Office")
    }
    
    async function ComputerGamer(event){
        event.preventDefault();
        setPCDefinied("defined")
        setPCDefiniedType("Gamer")
        SearchGetPC("Gamer")
    }

    async function GoToH(event){
        event.preventDefault();
        setPCDefinied("")
        setPCDefiniedType("")
    }
    
    async function goTOEvent(event){
        event.preventDefault();
        setMyComponent("")
    }

    async function RAM_Event(event){
        event.preventDefault();
        setMyComponent("RAM")
    }


    async function CPU_Event(event){
        event.preventDefault();
        setMyComponent("CPU")
    }

    async function DISK_EVENT(event){
        event.preventDefault();
        setMyComponent("DISCO")
    }

    async function GPU_Event(event){
        event.preventDefault();
        setMyComponent("GPU")
    }

    async function GABINETE_Event(event){
        event.preventDefault();
        setMyComponent("GABINETE")
    }

    async function FONTE_Event(event){
        event.preventDefault();
        setMyComponent("FONTE")
    }


     async function SearchGetPC(MyPC){
       try{ await api.post('getpc',{
            pcfor: MyPC
        }).then(response => {
            setDataHW(response.data)
        })
    } catch (error){
        console.log(error)
    }
    }



    return (
        <div id="Home">
            <HomeLayout 
            homego="true"
            pagead="true"
            />
            <div id="footermount">
                { (PCDefinied === "defined") ||
                <div className="columnfootermount">
                    <img src={PCOffice} alt=""/>
                    <button onClick={ComputerHome}>
                        Computador Pessoal
                    </button>
                </div>
                                }

                { (PCDefinied === "defined" )||
                <div className="columnfootermount gamercolormount">
                    <img src={PCGamer} alt=""/>
                    <button onClick={ComputerGamer}>
                        Computador Gamer
                    </button>
                </div>}
            </div>
            { (PCDefinied === "defined") &&
                        <div id="listcomp">
                <div className="columnlistinity">

                { (PCDefiniedType === "Gamer") && 
                <img src={PCGamer} alt=""/>
                            }
                { (PCDefiniedType === "Office") && 
                <img src={PCOffice} alt=""/>
                            }
                <a>{PCDefiniedType}</a>
                <button onClick={GoToH}>
                        Trocar Categoria
                    </button>
                <div className="myprocessor">
                    Processador
                    <img onClick={CPU_Event}  src={ProcessorImg} alt=""/>
                </div>
                <div className="mymemory">
                    Memória Ram
                    <img onClick={RAM_Event} src={MemoryImage} alt=""/>
                </div>
                <div className="mydisk">
                    Discos
                    <img onClick={DISK_EVENT} src={DiskImg} alt=""/>
                </div>
                <div onClick={GABINETE_Event} className="mycabinet">
                    Gabinete
                    <img src={cabinetImg} alt=""/>
                </div>
                <div onClick={FONTE_Event} className="myfont">
                    Fonte
                    <img src={fountComputer} alt=""/>
                </div>
                { (PCDefiniedType === "Gamer") && 
                                <div onClick={GPU_Event} className="mygpu">
                                GPU
                                <img src={GPUImg} alt=""/>
                            </div>
                            }
            </div>
            </div>
            }

              { (MyComponent) &&
                <div id="listcompHW">
                    <button onClick={goTOEvent}>
                        <IoIosArrowBack color="#0f6bff" size="35" />
                    </button>
                    <div className="columnmountmycomp" >
                        {
                 dataHW.map(({id, name, type, price, image, pcfor} ) =>{
                    if (type === MyComponent) {
                        if (pcfor === PCDefiniedType || pcfor === "Multiplos") {
                    return <ProductHardwareMap key={id} idproduct={id} name={name} image={image} price={price} type={type}/>
                    }
                }
                })
                }
                    </div>
                </div>
              }
        </div>

    )
}

export default MountPC;